import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/SEO";

export const pageQuery = graphql`
  query CoockyQuery {
    prismicSeo(data: { name: { eq: "Mosano | Cookies Policy" } }) {
      data {
        name
        title
        description
        keywords
        canonical_url {
          url
        }
        image {
          url
        }
      }
    }
    prismicCoockiesPolicy(uid: { eq: "coockies" }) {
      data {
        coockies_text {
          html
        }
      }
      uid
    }
    prismicCookieConsent(uid: { eq: "cookie_consent" }) {
      data {
        setting_button_label
        accept_button_label
        decline_button_label
        close_button_label
        close_icon_alt
        title {
          text_1
          text_2
          text_3
        }
        cookies_categories {
          name
          details {
            text
          }
          policy_link {
            url
          }
          policy_link_text
          expand_button_label
          status
        }
      }
    }
  }
`;

const Cookiespolicy = (props: any) => {
  const CoockiesPolicy = props.data.prismicCoockiesPolicy;
  return (
    <>
      <SEO {...props.data.prismicSeo.data} />
      <div className="gradient" style={{ padding: "150px 0 40px" }}>
        <div className="container-hero-home">
          <h1 className="heading large">How we handle Cookies</h1>
          <div
            className="p1 cookies"
            dangerouslySetInnerHTML={{
              __html: CoockiesPolicy.data.coockies_text.html,
            }}
          />
          <div className="p1 cookies">
            <p><strong>MANAGE COOKIES</strong></p>
            <button 
              className="button w-button mosano-button-more-information button-box" 
              onClick={() => {
                // @ts-ignore
                window.cookiehub.openSettings();
              }}
            >
              Cookies Settings
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cookiespolicy;
